<style scoped>
  .image-success{
    max-width: 300px;
    margin: 0 auto;
    }
</style>

<script>
import UIImage from '@components/UIImage';

export default {
	name: 'LoginSuccess',
	components:{
		UIImage
	},
	methods:{
		goto(){
			this.$router.push({name: 'login.home'});
		}
	}
}
</script>

<template>
	<div class="container">
		<div class="has-text-centered">
			<h2 class="title is-1">
				Que bom ter você na {{ $store.state.ie.shortname }}!
			</h2>
			<h3 class="subtitle">
				Agora você já pode efetuar seu login usando os novos dados
			</h3>
		</div>

		<div class="columns mt-5">
			<div class="column is-6-desktop is-offset-3-desktop is-4-fullhd is-offset-4-fullhd">
				<div class="columns is-multiline has-text-centered">
					<UIImage
						class="image-success"
						src="reminder.svg"
					/>

					<div class="column is-12 has-text-centered mt-4">
						<button
							class="button is-primary is-rounded"
							@click="goto"
						>
							Fazer login
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>